function About() {
    return (
        <div className="max-w-4xl mx-auto bg-white p-8">
            <h1 className="text-2xl font-semibold my-4">About Us</h1>
            <p className="my-2">At JustRecipes, we help you save your favorite recipes forever, in a format that’s distraction-free and easy to use."</p>

            <p className="my-2">We know the frustration of finding a recipe online, only to get lost in ads, blog stories, 
                or—worst of all—lose the recipe altogether. That’s why we built JustRecipes: a platform that turns recipe 
                URLs into clean, printable recipe cards, so you can focus on cooking and keep your recipes forever.</p>

                
            <h1 className="text-xl font-semibold my-4">Our Mission</h1>
            <p>At JustRecipes, we’re passionate about making recipes simple, accessible, and timeless. Whether you’re planning meals, 
                building your personal recipe collection, or sharing favorite dishes with loved ones, we’re here to help you preserve the joy of cooking for years to come.</p>

            <h1 className="text-xl font-semibold my-4">What We Do</h1>
            <p className="my-2">At JustRecipes, we make cooking more enjoyable by simplifying your experience:</p>

            <ul className="list-disc list-inside pl-2">
                <li>Remove the Clutter: Paste the URL of any recipe into our site, and we’ll extract only the essentials—ingredients, instructions, and cooking times.</li>
                <li>Create Printable Recipe Cards: Get beautifully formatted recipe cards that are perfect for saving digitally or printing for your recipe binder.</li>
                <li>Save Time: Focus on cooking instead of scrolling through blogs, ads, and life stories.</li>
                <li>Preserve Your Favorites Forever: Once you generate a recipe card, it’s yours to keep—no more lost bookmarks or disappearing links.</li>
            </ul>

            <div className="flex justify-center  my-4">
                <img className="max-w-xl" src="/images/example-recipe.jpg" alt="example recipe"></img>
            </div>

            <h1 className="text-xl font-semibold my-4">How It Works</h1>
            <p className="my-2">Using JustRecipes is simple:</p>
            <ul className="list-disc list-inside pl-2">
                <li>Find a Recipe Online: Browse your favorite recipe blogs or sites as usual.</li>
                <li>Paste the URL: Copy the link to the recipe and paste it into our input box.</li>
                <li>Generate & Save: With one click, receive a clean, printable recipe card that’s ready to print, save, share, or store for the future.</li>
            </ul>

            <p className="my-2">JustRecipes is here to save you time and help you enjoy what matters most—cooking and sharing delicious meals with the people you love.</p>
            <p className="my-2">Try it today and experience the difference for yourself. Happy cooking!</p>
        </div> 
    );
}

export default About;