import { FormEvent, useState } from "react";

function Contact() {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        // Extract form data
        const formData = new FormData(e.currentTarget);
        const data = {
            name: formData.get('name') as string,
            email: formData.get('email') as string,
            subject: formData.get('subject') as string,
            message: formData.get('message') as string,
        };

        try {
            // Simulate sending data to the backend
            const response = await fetch(`${process.env.REACT_APP_JUST_RECIPES_BACKEND_BASE_URL}/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Failed to send the message.');
            }

            setSuccess(true);
        } catch (error) {
            alert('There was an error sending your message. Please try again.');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    return ( 
    <div>
        <section className="max-w-4xl mx-auto bg-white p-8">
            {!success ? (
                    <div>
                        <h2 className="text-2xl font-semibold mb-4">Contact Form</h2>
                        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                            <label htmlFor="name" className="text-lg">Your Name:</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                placeholder="Enter your name"
                                required
                                className="p-3 text-base border border-gray-300 rounded-md w-full box-border"
                                disabled={loading}
                            />

                            <label htmlFor="email" className="text-lg">Your Email Address:</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Enter your email"
                                required
                                className="p-3 text-base border border-gray-300 rounded-md w-full box-border"
                                disabled={loading}
                            />

                            <label htmlFor="subject" className="text-lg">Subject:</label>
                            <select
                                id="subject"
                                name="subject"
                                required
                                className="p-3 text-base border border-gray-300 rounded-md w-full box-border"
                                disabled={loading}
                            >
                                <option value="">Select a subject</option>
                                <option value="general">General Inquiry</option>
                                <option value="feedback">Feedback</option>
                                <option value="support">Technical Support</option>
                            </select>

                            <label htmlFor="message" className="text-lg">Message:</label>
                            <textarea
                                id="message"
                                name="message"
                                rows={6}
                                placeholder="Type your message here..."
                                required
                                className="p-3 text-base border border-gray-300 rounded-md w-full box-border"
                                disabled={loading}
                            ></textarea>

                            <button
                                type="submit"
                                className={`text-base p-3 font-semibold text-white bg-black border-solid border-2 border-black ${
                                    loading ? 'opacity-50' : ''
                                }`}
                                disabled={loading}
                            >
                                {loading ? 'Sending...' : 'Submit'}
                            </button>
                        </form>
                    </div>
                ) : (
                    <div className="text-center">
                        <h2 className="text-2xl font-semibold mb-4">Thank You!</h2>
                        <p className="text-lg">
                            Your message has been received successfully. We will get back to you as
                            soon as we can!
                        </p>
                    </div>
                )}
        </section>
    </div> 
    );
}

export default Contact;