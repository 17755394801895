import { NavLink } from "react-router";

function Footer() {
    return (
        <div className='mt-10'>
            <div className='h-1.5 bg-gray-100 mb-10'></div>
            <div>
                This website is currently in beta, which means we’re still actively working to improve your experience. 
                While we’re excited to share what we’ve built so far, you may encounter some features that aren’t fully 
                polished or areas that don’t quite meet your expectations. Please know that we’re listening and working 
                hard to make things better every day. We’d love to hear your thoughts — if you have any feedback, suggestions, 
                or run into any issues, please let us know! Check back often as we’re continuously updating and improving the site. 
                Your input will help shape the future of this platform. Thank you for being part of our journey!
            </div>
            <div className='h-3.5 bg-gray-100 mt-10'></div>
            <div className='h-36 flex flex-col justify-between py-5 gap-3'>
                <div className='flex justify-between gap-3'>
                    <div>
                        <NavLink className="text-base font-semibold" to="/">JustRecipes</NavLink>
                        <div className='text-sm text-gray-500 hidden md:block'>Easily print recipe cards for your favorite recipes on the web</div>
                    </div>
                    <div className='footer-links'>
                        <div className='flex flex-col'>
                            <NavLink className='text-base' to="about">About Us</NavLink>
                            <NavLink className='text-base' to="contact">Contact Us</NavLink>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex justify-center items-center gap-2 text-gray-700 flex-col md:flex-row">
                        <div className="text-sm">Copyright ©2024 JustSoft, LLC</div>
                        <div className="flex justify-center items-center gap-2">
                            <span className="hidden md:block">·</span>
                            <NavLink className="text-sm" to="terms">
                                Terms Of Use
                            </NavLink>
                            <span>·</span>
                            <NavLink className="text-sm" to="privacy">
                                Privacy Policy
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;