const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto bg-white p-8">
      <h1 className='text-2xl font-semibold my-4'>Privacy Policy for JustRecipes.ai</h1>
      <p className='mb-2'><strong>Effective Date:</strong> 1/13/25</p>

      <p>
        At <strong>JustRecipes</strong>, accessible from <strong>https://www.justrecipes.ai/</strong>, 
        the privacy of our visitors is of utmost importance to us. This Privacy Policy outlines the 
        types of information we collect and how we use it, in compliance with Google AdSense 
        and other legal requirements.
      </p>

      <h2 className='text-xl my-4'>1. Information We Collect</h2>
      <p className='mb-2'>We collect information in the following ways:</p>
      <ul className="list-disc list-inside pl-2">
        <li><strong>Personal Information:</strong> Information you voluntarily provide, such as your name, email address, or other contact details when you subscribe to our newsletter or fill out a contact form.</li>
        <li><strong>Non-Personal Information:</strong> Information automatically collected, such as your IP address, browser type, device type, and browsing behavior on our site.</li>
      </ul>

      <h2 className='text-xl my-4'>2. Use of Information</h2>
      <p className='mb-2'>We use the collected information for the following purposes:</p>
      <ul className="list-disc list-inside pl-2">
        <li>To provide, operate, and maintain our website.</li>
        <li>To improve user experience by analyzing how visitors interact with our website.</li>
        <li>To communicate with you, including responding to inquiries and sending updates.</li>
        <li>To serve personalized ads through Google AdSense and other advertising platforms.</li>
      </ul>

      <h2 className='text-xl my-4'>3. Google AdSense</h2>
      <p className='mb-2'>
        We use Google AdSense to display advertisements on our website. Google uses cookies to serve ads 
        based on your previous visits to this site and other websites. These cookies enable Google and its 
        partners to serve ads based on your interests.
      </p>
      <p>
        For more information on how Google uses data, please visit the 
        <a href="https://policies.google.com/technologies/partner-sites" target="_blank" rel="noopener noreferrer">
          <strong> Google Privacy & Terms page</strong>
        </a>.
      </p>

      <h2 className='text-xl my-4'>4. Cookies and Tracking Technologies</h2>
      <p className='mb-2'>
        Our website uses cookies and similar technologies to enhance user experience and analyze website performance. 
        These may include:
      </p>
      <ul className="list-disc list-inside pl-2 mb-2">
        <li>Session cookies: To ensure smooth navigation on the website.</li>
        <li>Analytical cookies: To understand website traffic and improve functionality.</li>
        <li>Advertising cookies: To display personalized ads through Google AdSense and other platforms.</li>
      </ul>
      <p>
        You can manage your cookie preferences through your browser settings or opt out of personalized advertising 
        by visiting the 
        <a href="https://www.aboutads.info/choices/" target="_blank" rel="noopener noreferrer">
          AdChoices website
        </a>.
      </p>

      <h2 className='text-xl my-4'>5. Third-Party Services</h2>
      <p >
        We may use third-party services, including Google Analytics and advertising networks, 
        which may collect and process your information. These services are governed by their own privacy policies.
      </p>

      <h2 className='text-xl my-4'>6. Data Protection</h2>
      <p>
        We prioritize the security of your data and implement measures to protect it against unauthorized 
        access, alteration, disclosure, or destruction.
      </p>

      <h2 className='text-xl my-4'>7. Your Rights</h2>
      <p className='mb-2'>You have the following rights regarding your data:</p>
      <ul className="list-disc list-inside pl-2 mb-2">
        <li>Access your personal information.</li>
        <li>Request corrections to your personal information.</li>
        <li>Request deletion of your personal information.</li>
        <li>Opt out of personalized advertising.</li>
      </ul>
      <p>
        To exercise these rights, please contact us at <strong>support@justrecipes.ai</strong>.
      </p>

      <h2 className='text-xl my-4'>8. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be posted on this page 
        with an updated "Effective Date." Your continued use of the website constitutes your acceptance of the changes.
      </p>

      <h2 className='text-xl my-4'>9. Contact Us</h2>
      <p className='mb-2'>
        If you have any questions or concerns about this Privacy Policy, please contact us:
      </p>
      <ul className="list-disc list-inside pl-2">
        <li>Email: support@justrecipes.ai</li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
