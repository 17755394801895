import { NavLink } from 'react-router';

const TermsOfService = () => {
  return (
    <div className="max-w-4xl mx-auto bg-white p-8">
      <h1 className='text-2xl font-semibold my-4'>Terms of Service for JustRecipes</h1>
      <p><strong>Last Updated:</strong> 1/13/2025</p>

      <p>Welcome to <strong>JustRecipes</strong> ("we," "our," or "us"). By accessing or using our website, <strong>https://www.justrecipes.ai/</strong>, you agree to comply with and be bound by the following Terms of Service ("Terms"). If you do not agree to these Terms, please do not use the site.</p>

      <h2 className='text-xl my-4'>1. Acceptance of Terms</h2>
      <p>By using our website, you affirm that you are at least 18 years old or have parental consent to use this site. You agree to abide by these Terms of Service and all applicable laws and regulations.</p>

      <h2 className='text-xl my-4'>2. Use of the Website</h2>
      <p className='mb-2'>You may use our website for lawful purposes and in accordance with these Terms. You agree not to use the site:</p>
      <ul className="list-disc list-inside pl-2">
        <li>In any way that violates any applicable local, state, national, or international law or regulation.</li>
        <li>To exploit, harm, or attempt to exploit or harm minors in any way, including by exposing them to inappropriate content.</li>
      </ul>

      <h2 className='text-xl my-4'>3. Content</h2>
      <p className='mb-2'>All content provided on this website, including recipes, blog posts, articles, and any associated media ("Content"), is for general information purposes only. We make no guarantees regarding the accuracy, completeness, or suitability of the Content for any particular purpose.</p>
      <p>You may not copy, reproduce, distribute, or display any of the Content without prior written consent from us.</p>

      <h2 className='text-xl my-4'>4. User Accounts</h2>
      <p>To access certain features of the website, you may be required to create an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately if you suspect any unauthorized access to your account.</p>

      <h2 className='text-xl my-4'>5. Privacy Policy</h2>
      <p>Your use of the website is also governed by our <NavLink to="/privacy"><strong>Privacy Policy</strong></NavLink>, which explains how we collect, use, and share your personal information.</p>

      <h2 className='text-xl my-4'>6. Intellectual Property</h2>
      <p>All intellectual property rights, including copyrights, trademarks, and patents related to the website and its Content, are owned by JustRecipes.ai or its licensors. Unauthorized use of the website's content or any of its trademarks is prohibited.</p>

      <h2 className='text-xl my-4'>7. Disclaimers and Limitations of Liability</h2>
      <ul className="list-disc list-inside pl-2">
        <li>We do not warrant the accuracy, reliability, or completeness of any Content on this website.</li>
        <li>We do not assume any responsibility for any errors, omissions, or inaccuracies in the Content.</li>
        <li>Under no circumstances shall we be liable for any indirect, incidental, or consequential damages arising from the use or inability to use the website.</li>
      </ul>

      <h2 className='text-xl my-4'>8. Termination</h2>
      <p>We may suspend or terminate your access to the website at any time, without notice, for conduct that we believe violates these Terms or is harmful to other users, us, or third parties.</p>

      <h2 className='text-xl my-4'>9. Governing Law</h2>
      <p>These Terms shall be governed and construed in accordance with the laws of The United States of America, without regard to its conflict of law principles.</p>

      <h2 className='text-xl my-4'>10. Changes to Terms</h2>
      <p>We reserve the right to modify or update these Terms at any time. Any changes will be posted on this page with an updated "Last Updated" date. Your continued use of the website after any such changes constitutes your acceptance of the new Terms.</p>

      <h2 className='text-xl my-4'>11. Contact Information</h2>
      <p className='mb-2'>If you have any questions about these Terms, please contact us at:</p>
      <ul className="list-disc list-inside pl-2">
        <li>Email: support@justrecipes.ai</li>
      </ul>
    </div>
  );
};

export default TermsOfService;
