import { Ref, forwardRef, useEffect, useRef } from "react";
import { Recipe } from "../../interfaces/Recipe";
import { fitTextInElement } from "./text-utils";

const BasicTwoSidedRecipeCard = forwardRef((props: {recipe: Recipe}, ref: Ref<HTMLDivElement>) => {

    const {recipe} = props;

    const firstColumnIngredientRef = useRef<HTMLDivElement>(null);
    const secondColumnIngredientRef = useRef<HTMLDivElement>(null);
    const instructionsElementRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        const sizes = fitTextInElement([firstColumnIngredientRef.current, instructionsElementRef.current], {multiLine: true, maxFontSize: 14, minFontSize: 4});
        if(sizes && sizes.length !== 0 && sizes[0] && secondColumnIngredientRef.current) {
            secondColumnIngredientRef.current.style.fontSize = sizes[0] + 'px';
        }
      }, [recipe]);

    return ( 
        <div className="flex flex-col gap-3">         
            <div className="border-l-4 border-gray-400 text-s bg-gray-100 px-2">
                To print a two-sided recipe card, either use your printer’s double-sided print option or print one page at a time, arranging the paper to print on the back of the first page.
            </div>
            <div ref={ref}>
                <div className="flex flex-wrap gap-3 min-w-[6in]">
                    <div className="p-4 w-[6in] h-[4in] border flex flex-col bg-white">
                        <div className="flex flex-col h-[1in] gap-1">
                            <div className="text-lg font-bold flex justify-center">
                                {recipe.Name}
                            </div>
                            <div className="flex justify-evenly font-medium text-sm">
                                <div>
                                    Cook Time: {recipe.CookTime}
                                </div>
                                <div>
                                    Servings: {recipe.Yield}
                                </div>
                            </div>
                            <div className="border-t border-black mt-3"></div>
                        </div>
                        <div className="flex h-[3in]">
                            <div className="flex flex-col gap-1 w-full">
                                <div className="font-semibold text-sm">Ingredients:</div>
                                <div>
                                <div className="flex leading-normal overflow-hidden h-[264px] gap-3" id="ingredients">
                                    <div className="basis-1/2" ref={firstColumnIngredientRef}>
                                        {recipe.Ingredients.slice(0, Math.ceil(recipe.Ingredients.length / 2)).map((x, i) => <div key={`ingredient-${i}`}>{x}</div>)}
                                    </div>
                                    <div className="basis-1/2" ref={secondColumnIngredientRef}>
                                        {recipe.Ingredients.slice(Math.ceil(recipe.Ingredients.length / 2)).map((x, i) => <div key={`ingredient-${i}`}>{x}</div>)}
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                    <div className="pagebreak"></div>
                    <div className="p-4 w-[6in] h-[4in] border flex flex-col page-two bg-white">
                        <div className="flex flex-col gap-1 h-full">
                            <div className="font-semibold text-sm">Directions:</div>
                            <div className="flex flex-col overflow-hidden h-[325px]" ref={instructionsElementRef}>
                                {recipe.Instructions.map((x, i) => <li key={`direction-${i}`}>{x}</li>)}
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    );
});

export default BasicTwoSidedRecipeCard;
