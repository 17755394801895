import { ChangeEvent, useEffect, useRef, useState } from 'react';
// import './Home.scss';
import { Recipe } from '../../interfaces/Recipe';
import BasicOneSidedRecipeCard from '../recipe-card/basic-one-sided-recipe-card';
import SpinnerButton from '../spinner-button/spinner-button';
import { useReactToPrint } from 'react-to-print';
import BasicTwoSidedRecipeCard from '../recipe-card/basic-two-sided-recipe-card copy';
import TwoSidedFancy from '../recipe-card/two-sided-fancy';
import { useNavigate } from "react-router";

function Home() {
  let navigate = useNavigate();

  const [url, setUrl] = useState("");
  const recipe = {
    Name: "Chocolate Chip Cookies",
    Yield: "4 Servings",
    PrepTime: "10 Minutes",
    CookTime: "27 Minutes",
    TotalTime: "37 Minutes",
    Ingredients: [
      "1 C. Shortening",
      "1 C. Brown Sugar",
      "1/2 C. Sugar",
      "2 Eggs",
      "1 t. Vanilla",
      "1 t. Baking Soda",
      "1 t. Salt",
      "2 1/2 C. Flour",
      "1 1/2 C. Semi-Sweet Chocolate Chips"
    ],
    Instructions: [
      "Cream together Shortening, Brown Sugar, and Sugar",
      "Mix in Eggs and Vanilla",
      "Add Baking Soda, Salt, and Flour and mix until just combined",
      "Add Chocolate Chips",
      "Place 1\" balls on baking sheet and bake at 375 degrees F. for 9 minutes or until lightly brown and cracked"
    ]
  } as Recipe;
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedRecipeCard, setSelectedRecipeCard] = useState("basic-one-sided");

  const recipeDivRef = useRef<HTMLDivElement>(null);

  const urlPattern = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
  const urlRegex = new RegExp(urlPattern);

  function handleGoClick() {
    if(url.match(urlRegex)) {
      navigate(`recipe-card/edit?url=${url}`);
    }
    else {
      setErrorMessage("The URL you have provided is invalid");
    }
  }

  function handleRecipeCardSelection(event: ChangeEvent<HTMLInputElement>): void {
    setSelectedRecipeCard(event.target.value);
  }

  function updateInputText(event: ChangeEvent<HTMLInputElement>): void {
    setUrl(event.target.value);
  }

  function handleEnterPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      handleGoClick();
    }
  };

  return (
    <div className="flex flex-col gap-16 p-6">
      <div className='flex gap-5'>
        <div className='flex flex-col lg:basis-1/2 justify-between'>
          <h1 className='font-semibold text-3xl sm:text-6xl mb-9 leading-tight mt-5'>
            Easily create printable recipe cards for recipes from the web!
          </h1>
          <div className='mb-5 bg-gray-50 p-6'>
            <div className='text-xl sm:text-2xl'>
              Enter a recipe URL in the box below and click “Go”
            </div>
            {errorMessage !== "" && <div className='mt-5 text-red-500'>{errorMessage}</div>}
            <input type='text' onChange={updateInputText} onKeyDown={handleEnterPress} placeholder='https://www.recipe-url.com/recipe'
            className='w-full rounded-lg text-sm h-10 px-2 box-border shadow-text-input my-5' />
            <SpinnerButton loading={false} onClick={handleGoClick}>Go</SpinnerButton>
          </div>
        </div>
        <div className='hidden lg:flex justify-center basis-1/2 p-5 shrink'>
          <img src='/images/recipe-box.jpg' className='max-w-full m-auto bg-gray-50 p-5' alt="A recipe card box"/>
        </div>
      </div>
      <section className="text-center">
          <h2 className="text-2xl font-semibold mb-4">Effortless Recipe Management</h2>
          <p className="mb-6">
            Simplify your cooking experience with printable recipe cards. Save, share, and organize your favorite recipes in one click.
          </p>
        </section>
      <section className="grid grid-cols-1 md:grid-cols-3 gap-8 bg-gray-50 p-6">
        <div className="p-6 bg-white shadow rounded-lg">
          <h3 className="text-xl font-semibold mb-2">Easy to Use</h3>
          <p>Paste the URL of your favorite recipe, and we’ll do the rest. Create professional recipe cards in seconds.</p>
        </div>
        <div className="p-6 bg-white shadow rounded-lg">
          <h3 className="text-xl font-semibold mb-2">Customizable</h3>
          <p>Choose from a variety of templates to suit your style and make your recipes stand out.</p>
        </div>
        <div className="p-6 bg-white shadow rounded-lg">
          <h3 className="text-xl font-semibold mb-2">Print or Save</h3>
          <p>Download or print your recipe cards for easy access during your cooking adventures.</p>
        </div>
      </section>
      <hr />
      <section className="px-6 rounded-lg">
        <h2 className="text-2xl font-semibold mb-4">Why Choose Recipe Card Creator?</h2>
        <ul className="list-disc pl-5">
          <li>Save time by transforming online recipes into ready-to-use cards instantly.</li>
          <li>Organize your favorite dishes for meal planning or sharing with friends.</li>
          <li>Use our templates for an aesthetic and professional look.</li>
          <li>Free to use with optional premium features for advanced customization.</li>
        </ul>
      </section>
      <hr />
      <div ref={recipeDivRef} className='flex flex-col gap-6 px-5'>
        <div className='text-2xl font-semibold text-gray-800'>
          Enjoy this sample recipe (which is delicious if you ask us!)
        </div> 
        <div className='flex flex-col gap-6'>
          <div className='flex gap-3'>
            <div>Choose sample template:</div>
            <div>
              <label>
                <input className='mr-1' type='radio' value="basic-one-sided" checked={selectedRecipeCard === "basic-one-sided"} onChange={handleRecipeCardSelection} />
                One Sided
              </label>
            </div>
            <div>
              <label>
                <input className='mr-1' type='radio' value="basic-two-sided" checked={selectedRecipeCard === "basic-two-sided"} onChange={handleRecipeCardSelection} />
                Two Sided
              </label>
            </div>
            <div>
              <label>
                <input className='mr-1' type='radio' value="fancy-two-sided" checked={selectedRecipeCard === "fancy-two-sided"} onChange={handleRecipeCardSelection} />
                Elegant Two Sided
              </label>
            </div>
          </div>
          <div className='flex justify-center scale-[.65] md:transform-none'>
            {selectedRecipeCard === "basic-one-sided" && <BasicOneSidedRecipeCard recipe={recipe} />}
            {selectedRecipeCard === "basic-two-sided" && <BasicTwoSidedRecipeCard recipe={recipe} />}
            {selectedRecipeCard === "fancy-two-sided" && <TwoSidedFancy recipe={recipe} />}
          </div>
        </div>
      </div>
      <section className="bg-gray-100 p-6 rounded-lg">
        <h2 className="text-2xl font-semibold mb-4">What Our Users Are Saying</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="bg-white shadow rounded-lg p-4">
            <p className="text-gray-700 italic">"This tool has revolutionized the way I save and use recipes. Highly recommend!"</p>
            <p className="text-gray-800 font-semibold mt-2">- Erika</p>
          </div>
          <div className="bg-white shadow rounded-lg p-4">
            <p className="text-gray-700 italic">"Creating recipe cards has never been easier. Love the templates!"</p>
            <p className="text-gray-800 font-semibold mt-2">- Debbie</p>
          </div>
          <div className="bg-white shadow rounded-lg p-4">
            <p className="text-gray-700 italic">"A must-have for every home chef. Simplifies meal planning and sharing."</p>
            <p className="text-gray-800 font-semibold mt-2">- Anonymous</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;

