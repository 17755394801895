import { NavLink } from "react-router";

function Header() {
    return (
        <div className="h-24 flex items-center">
            <NavLink to="/"><img src="/images/logo-bold.png" alt="JustRecipes logo"></img></NavLink>
        </div>
    );
}

export default Header;