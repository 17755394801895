import { Outlet } from "react-router";
import Footer from "./footer/footer";
import Header from "./header/header";

function Layout( { children }: any) {
    return ( 
        <>
            <div>
                <Header></Header>
                    <Outlet />
                <Footer></Footer>
            </div>
        </>
     );
}

export default Layout;