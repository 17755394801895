import './App.scss';
import { BrowserRouter, Routes, Route } from 'react-router';
import About from './components/about';
import Home from './components/home/home';
import Layout from './components/layout/layout';
import Contact from './components/contact';
import TermsOfService from './components/terms-of-service';
import PrivacyPolicy from './components/privacy-policy';
import RecipeCardEdit from './components/recipe-card-edit';

function App() {

  return (
    <div className="px-5 sm:px-20">
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="terms" element={<TermsOfService />} />
            <Route path="privacy" element={<PrivacyPolicy />} />
            <Route path="recipe-card/edit" element={<RecipeCardEdit />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

