import { ChangeEvent, useEffect, useRef, useState } from "react";
import SpinnerButton from "./spinner-button/spinner-button";
import { Recipe } from "../interfaces/Recipe";
import { useReactToPrint } from "react-to-print";
import BasicOneSidedRecipeCard from "./recipe-card/basic-one-sided-recipe-card";
import BasicTwoSidedRecipeCard from "./recipe-card/basic-two-sided-recipe-card copy";
import TwoSidedFancy from "./recipe-card/two-sided-fancy";
import { useLocation, useSearchParams } from "react-router";

function RecipeCardEdit() {
    const location = useLocation();

    const [loadingRecipe, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [url, setUrl] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [recipe, setRecipe] = useState<Recipe>({
        Name: "Sample Recipe",
        Yield: "4 Servings",
        PrepTime: "10 Minutes",
        CookTime: "20 Minutes",
        TotalTime: "30 Minutes",
        Ingredients: ["Ingredient 1", "Ingredient 2", "Ingredient 2"],
        Instructions: ["Instruction 1", "Instruction 2"]
    } as Recipe);

    const [selectedRecipeCard, setSelectedRecipeCard] = useState("basic-one-sided");

    const recipeDivRef = useRef<HTMLDivElement>(null);
    const recipeToPrintRef = useRef<HTMLDivElement>(null);

    const urlPattern = /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    const urlRegex = new RegExp(urlPattern);

    useEffect(() => {
        setLoading(true);
        const urlParam = tryGetUrlFromQueryString()

        if(urlParam) {
            setUrl(urlParam);

            if(validateRecipe(urlParam)) {
                fetchRecipe(urlParam);
            }
        }
      }, [location.search]);

    useEffect(() => {
        if (recipe.Name !== "Sample Recipe") {
            recipeDivRef.current?.scrollIntoView();
        }
    }, [recipe])

    async function fetchRecipe(urlParam = url): Promise<void> {
        console.log(urlParam);
        try {
            const response = await fetch(`${process.env.REACT_APP_JUST_RECIPES_BACKEND_BASE_URL}/web?url=${urlParam}`);
            if (response.ok) {
                const recipeText = await response.text();
                const recipe = JSON.parse(htmlDecode(recipeText)) as Recipe
                setRecipe(recipe);
            }
            else {
                setErrorMessage("Sorry! We couldn’t retrieve the recipe from the provided URL. Our team is aware of the issue and is working diligently to resolve it. Thank you for your patience.");
            }
        } catch (error) {
            setErrorMessage('Sorry! An issue occurred, and we are currently unable to retrieve the recipe.');
        }
        setLoading(false);
    }

    function validateRecipe(urlParam: string) : boolean {
        if(urlParam === "") {
            setErrorMessage("You must provide a URL");
            return false;
        }
        if (urlParam.match(urlRegex)) {
            return true;
        }
        else {
            setErrorMessage("The URL you have provided is invalid");
            return false;
        }
    }

    function handleGoClick() {
        setErrorMessage("");
        setLoading(true);
        if(validateRecipe(url)) {
            if(tryGetUrlFromQueryString() === url) {
                fetchRecipe(url);
            }
            else {
                setUrlQueryString(url);
            }
        }
    }

    function setUrlQueryString(urlParam: string) {
        setSearchParams({ url: urlParam });
    }

    function tryGetUrlFromQueryString() : string | null {
        return searchParams.get("url");
    }

    function updateInputText(event: ChangeEvent<HTMLInputElement>): void {
        setUrl (event.target.value);
    }

    function handleEnterPress(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
          handleGoClick();
        }
      };

    const print = useReactToPrint({
        content: () => recipeToPrintRef.current,
    });

    function htmlDecode(input: string): string {
        var doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.textContent ?? "";
    }

    function handleRecipeCardSelection(event: ChangeEvent<HTMLInputElement>): void {
        setSelectedRecipeCard(event.target.value);
    }

    return (
        <div className="flex flex-col gap-12 p-6">
            <div>
                <div className='text-xl sm:text-2xl text-gray-600'>
                    Enter a recipe URL in the box below and click “Go”
                </div>
                {errorMessage !== "" && <div className='mt-5 text-red-500'>{errorMessage}</div>}
                <input type='text' onChange={updateInputText} onKeyDown={handleEnterPress} value={url} placeholder='https://www.recipe-url.com/recipe'
                    className='w-full rounded-lg text-sm h-10 px-2 box-border shadow-text-input my-5' />
                <SpinnerButton loading={loadingRecipe} onClick={handleGoClick}>Go</SpinnerButton>
            </div>
            <div ref={recipeDivRef} className='flex flex-col gap-6'>
                <div className='flex'>
                    <div className='text-3xl sm:text-4xl text-gray-800'>
                        {recipe.Name !== "Sample Recipe" ? "Customize and print your recipe" : "Sample Recipe Card"}
                    </div>
                </div>
                <div className='flex flex-col gap-3'>
                    <div className='flex gap-3'>
                        <div>
                            <label>
                                <input className='mr-1' type='radio' value="basic-one-sided" checked={selectedRecipeCard === "basic-one-sided"} onChange={handleRecipeCardSelection} />
                                One Sided
                            </label>
                        </div>
                        <div>
                            <label>
                                <input className='mr-1' type='radio' value="basic-two-sided" checked={selectedRecipeCard === "basic-two-sided"} onChange={handleRecipeCardSelection} />
                                Two Sided
                            </label>
                        </div>
                        <div>
                            <label>
                                <input className='mr-1' type='radio' value="fancy-two-sided" checked={selectedRecipeCard === "fancy-two-sided"} onChange={handleRecipeCardSelection} />
                                Elegant Two Sided
                            </label>
                        </div>
                    </div>
                    {selectedRecipeCard === "basic-one-sided" && <BasicOneSidedRecipeCard ref={recipeToPrintRef} recipe={recipe} />}
                    {selectedRecipeCard === "basic-two-sided" && <BasicTwoSidedRecipeCard ref={recipeToPrintRef} recipe={recipe} />}
                    {selectedRecipeCard === "fancy-two-sided" && <TwoSidedFancy ref={recipeToPrintRef} recipe={recipe} />}
                </div>
                {recipe.Name !== "Sample Recipe" &&
                    <button onClick={print} className='w-44 font-semibold h-12 text-white bg-black border-solid border-2 border-black'>Print</button>}
            </div>
        </div>
    );
}

export default RecipeCardEdit;